#test {
    margin: 0;
    padding: 0;
    display: flex;
}

#test #test2 {
    list-style: none;
    margin: 0 8px;
}

#test #test2 #test3 {
    position: relative;
    display: block;
    width: 33px;
    height: 33px;
    text-align: center;
    line-height: 34px;
    background: #333;
    border-radius: 50%;
    font-size: 19px;
    color: #666;
    transition: .5s;
}

#test #test2 #test3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #f4511e;
    transition: .5s;
    transform: scale(.9);
    z-index: -1;
}

#test #test2 #test3:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #f4511e;
}

#test #test2 #test3:hover {
    color: #f4511e;
    box-shadow: 0 0 5px #f4511e;
    text-shadow: 0 0 5px #f4511e;
}

@media screen and (max-width: 460px) {
    #test {
        visibility: hidden;
    }
}

.social-icon-center {
    left: 50px;
}

.social-icon-align {
    margin: auto !important;
}