video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    /*background: url('./assets/images/img-home.jpg') center center/cover no-repeat;*/
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > div > div > h1 {
    color: #fff;
    font-size: 60px;
}

.hero-container > div > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.main-text {
    color: #fff;
    font-size: 110px !important;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

@media screen and (max-width: 1586px) {
    .home-img {
        width: inherit !important;
    }
}

next-icon-hide {
    display: none;
}

@media screen and (min-width: 1586px) {
    .home-img {
        width: 100vw;
    }

    .background-text {
        margin-top: 100px;
    }
}

.img-blur {
    filter: blur(8px);
    animation: 3s 0s 1 blur-disappear forwards;
}

@keyframes blur-disappear {
    0% {
        filter: blur(8px);
    }
    100% {
        filter: blur(0px);
    }
}

@keyframes disappear {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
    }
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

.background-text {
    border: solid 2px  rgba(0, 0, 0, 0);
    padding: 50px 150px 50px 150px;
    border-radius: 1%;
    background: rgba(0, 0, 0, 0.5);
    animation: 2s 2s 1 disappear forwards;
    z-index: 100;
    top: 170px;
    position: absolute;
}

#homeC > .carousel-indicators {
    display: none !important;
}

#homeC > .carousel-control-prev-icon {
    display: none !important;
}

#homeC > .carousel-control-next-icon {
    display: none !important;
}

@media screen and (max-width: 1150px) {
    .hero-container > div > div > h1 {
        font-size: 70px;
    }

    .background-text {
        padding: 30px 90px 30px 90px;
        margin-top: -50px;
    }
}

@media screen and (max-width: 1050px) {
    .hero-container > div > div > h1 {
        font-size: 70px !important;
    }
}

@media screen and (max-width: 950px) {
    .hero-container > div > div > h1 {
        font-size: 50px !important;
    }

    .background-text {
        margin-top: -40px;
    }
}

@media screen and (max-width: 800px) {
    .main-text {
        font-size: 50px !important;
    }

    .hero-container > div > div > h1 {
        font-size: 40px !important;
    }

    .hero-container > div > div > p {
        font-size: 30px !important;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .hero-container > div > div > h1 {
        font-size: 30px !important;
    }

    .hero-container > div > div > p {
        font-size: 20px !important;
    }

    .background-text {
        padding: 15px 45px 15px 45px;
        margin-top: -30px;
    }
}

@media screen and (max-width: 540px) {
    .background-text {
        margin-top: -70px;
    }
}

@media screen and (max-width: 450px) {
    .main-text {
        font-size: 35px !important;
    }

    .hero-container > div > div > h1 {
        font-size: 24px !important;
    }

    .hero-container > div > div > p {
        font-size: 19px !important;
    }

    .background-text {
        padding: 10px 30px 10px 30px;
        margin-top: -75px;
    }
}