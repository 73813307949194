a {
    color: white;
    text-decoration: none;
}

a:hover {
    color: lightgray;
}

.footer-link {
    color: #fff;
    text-decoration: none;
}

.footer-link:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
    color: gray;
}

.foot-copyright {
    font-size: 12px;
}

.ml-15 {
    margin-left: 15px;
}

.footer-text-styling {
    font-size: 0.7em;
}

.modal-footer {
    border-top: 2px solid var(--mainSystemColor);
}

.footer-title {
    border: none !important;
    margin: 0 !important;
}

.align-icons {
    margin-top: -15px;
}

.icon-border {
    margin: 10px;
}

.icon-border:hover {
    opacity: 0.5;
}

@media screen and (max-width: 460px) {
    .follow-disable {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 767px) {
    .center-contact {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .center-contact {
        text-align: center;
    }

    .footer-text-styling {
        font-size: 15px !important;
    }
}

@media screen and (max-width: 1200px) {
    .center-contact {
        margin-bottom: 1em;
    }

    .align-icons {
        margin-top: 0;
    }
}