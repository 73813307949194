:root {
    --mainSystemColor: rgb(196, 128, 70, 0.9);
    --mainSystemColorOpacity: rgb(196, 128, 70, 0.5);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

body {
    width: 100.3%;
}

a {
    color: darkgray;
    text-decoration: none;
}

a:hover {
    color: #252930;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}

.header {
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    border-bottom: 2px solid var(--mainSystemColor);
    padding-bottom: 10px;
    margin-top: 10px !important;
    margin-bottom: 40px;
}

.header-smaller-gap {
    margin-top: 25px !important;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px !important;
    text-transform: uppercase;
    font-weight: 900;
    color: #fff;
    border-bottom: 2px solid  var(--mainSystemColor);
    padding-bottom: 10px;
    margin-bottom: 40px;
}

@media screen and (max-width: 576px) {
    .title {
        margin-bottom: 20px;
    }
}

.sub-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 19px !important;
    text-transform: uppercase;
    color: inherit;
    padding-bottom: 10px;
    margin-bottom: 15px;
}


.normal-text {
    font-family: 'Montserrat', sans-serif;
    font-size: .5em;
    text-transform: initial;
    color: #fff;
}

.text-l {
    font-size: 1.1em;
}


.text-xl {
    font-size: 1.3em;
}

.text-l-bold {
    font-weight: 900;
    font-size: 1.1em;
}

.home,
.lighter-theme,
.darker-theme {
    padding-left: 15px !important;
    padding-right: 15px !important;
    height: 900px;
    width: 100%;
    font-size: 1.1rem;
    margin-bottom: -5px;
}

.lighter-theme {
    background: #212121;
    color: #fff;
    margin-bottom: -5px;
}

.darker-theme {
    background: #1f2024;
    color: #fff;
}

.footer-theme {
    background: #1f2024;
    color: #fff;
    font-size: 1.5rem;
}

.margin-auto {
    margin: auto;
}

.container-height-auto {
    height: auto;
    padding-bottom: 80px;
}

.Toastify__toast-container {
    width: 330px !important;
}

.Toastify__toast--info {
    background: gray !important;
}

.padding-bottom-100 {
    padding-bottom: 100px !important;
}

#agreeModal {
    max-width: 490px !important;
    margin: 1.75rem auto;
}

#agreeModal .modal-body {
    padding: 15px;
}

@media screen and (min-width: 600px) {
    .MuiTab-root {
        min-width: 50% !important;
    }
}

.MuiButtonBase-root {
    font-size: 2.4em !important;
    font-weight: 600 !important;
    background: #1f2024 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #E6FEFFFF !important;
}

.PrivateTabIndicator-colorPrimary-3 {
    background-color: #E6FEFFFF !important;
}

[class*='MuiBox-root-'] {
    padding: 0 !important;
}

.MuiTab-textColorPrimary {
    color: #3c3c3c !important;
}

.MuiTypography-body1 {
    background: #1f2024;
}

.form-control:disabled, .form-control[readonly] {
    background-color: rgb(33, 37, 41) !important;
    opacity: 1;
}

@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px !important;
    }
}

.carousel-fade .carousel-item {
    transition: opacity ease-out 2.9s !important;
}