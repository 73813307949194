.remove-bottom-padding {
    height: 500px !important;
    min-height: 500px !important;
}

.carousel-button {
    width: 36px;
    background: #f4511e;
    border-radius: 50px;
    border-color: #f4511e;
    color: white;
}

.carousel-button:hover {
    background: #ff844c;
    border-color: #ff844c;
    color: white;
}

.carousel-button:focus {
    background: #f4511e;
    border-color: #f4511e;
    color: white;
}

.sc-bxivhb {
    margin-top: auto;
    margin-bottom: auto;
}

.carousel-icon:hover {
    opacity: 0.5;
}

@media screen and (min-width: 2500px) {
    .carousel-width {
        position: absolute;
        width: 80%;
        left: 18em;
    }
}

/* carousel left/right position */
@media screen and (max-width: 2500px) {
    .carousel-width {
        position: absolute;
        width: 88vw;
        left: 7em;
    }
}

@media screen and (max-width: 1800px) {
    .carousel-width {
        position: absolute;
        width: 84vw;
        left: 7em;
    }
}

@media screen and (max-width: 1800px) {
    .carousel-item-width {
        width: inherit;
    }
}

/* interval more then 1400 */
@media screen and (max-width: 1400px) {
    .numOfCards5 {
        display: none;
    }
}

/* interval 1200 - 1400 */
@media screen and (min-width: 1400px) {
    .numOfCards4 {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .numOfCards4 {
        display: none;
    }
}

/* interval 1000 - 1200 */
@media screen and (min-width: 1200px) {
    .numOfCards3 {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .numOfCards3 {
        display: none;
    }

    .carousel-item-width {
        width: initial;
    }
}

/* interval 800 - 1000 */
@media screen and (min-width: 1000px) {
    .numOfCards2 {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .numOfCards2 {
        display: none;
    }

    .too-long-logos {
        max-width: 240px;
    }
}

/* interval less then 800 */
@media screen and (min-width: 800px) {
    .numOfCards1 {
        display: none;
    }
}

@media screen and (max-width: 1320px) {
    .carousel-width {
        position: initial;
        width: 90%;
        margin-left: 5%;
    }
}