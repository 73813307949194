* {box-sizing: border-box;}

.image-container {
    position: relative;
    margin-top: 8px;
}

.image {
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    bottom: 0;
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: 1s ease;
    opacity:1;
    padding: 100px 0 0 15px;
    text-align: start;
    border-bottom: 40px solid rgb(255, 255, 255, 0.7);
}

.overlay-right {
    position: absolute;
    bottom: 0;
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: 1s ease;
    opacity:1;
    padding: 100px 0 0 15px;
    text-align: start;
    border-bottom: 40px solid rgb(255, 255, 255, 0.7);
}

@media screen and (max-width: 1060px) {
    .overlay {
        padding: 60px 0 0 10px;
    }

    .text {
        font-size: 1.4em !important;
    }

    .text-smaller {
        font-size: 1.9em !important;
    }

    .overlay-right {
        padding: 60px 10px 0 0;
    }
}

.text {
    font-size: 1.2em;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    color: black;
}

.text-smaller {
    font-size: 2.6em;
}

.float-right {
    float: right;
}

.image-container:hover .overlay-right{
    opacity: 0;
}

.image-container:hover .overlay{
    opacity: 0;
}

.image-height {
    max-height: 500px;
}

.image-height-carousel {
    max-height: 90vh;
    width: -webkit-fill-available;
}

@media screen and (min-width: 576px) {
    .modal-dialog {
        max-width: 80vw !important;
        margin: 1.75rem auto !important;
    }

    #modal-images {
        width: min-content !important;
    }
}

@media screen and (max-width: 576px) {
    #modal-images {
        width: initial !important;
    }
}

.modal-body {
    padding: 2px !important;
}
