.service-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 40px !important;


}

.card-gray {
    background: #6c757d;
}

.card-head-gray {
    background: #5e666e;
}

.mr-20 {
    margin-right: 20px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.ul-disable-bullets {
    list-style-type: none;
}

.nav-link {
    background: #232323 !important;
    color: white !important;
    width: 100% !important;
}

.nav-link.active {
    background:  var(--mainSystemColor) !important;
    border: 1px black solid !important;
}

.nav-link:focus {
    background:  var(--mainSystemColor) !important;
    border: 1px black solid !important;
}

.nav-link:hover {
    background:  var(--mainSystemColorOpacity) !important;
    border: 1px black solid !important;
}

.nav-tabs {
    border-bottom: none;
}

.nav-item {
    width: 50%;
    font-size: 1.3em;
}

#container > div > div > div:nth-child(9) > ul {
    position: relative;
    display: block;
}

#container > div > div > div:nth-child(9) > ul > li {
    width: 100% !important;
}

.gray-box {
    padding: 20px 0 20px 0;
}

.gray-box-padding {
    padding: 30px 50px 30px 50px;
}

.divider {
    border-bottom: 2px solid dimgray;
    width: 95%;
    margin: auto;
}

.divider-2 {
    border-bottom: 2px solid dimgray;
    width: 100%;
    margin: auto;
}

.btn-secondary:hover {
    border: 2px white solid;
}

.tabs-custom {
    padding: 0 !important;
}

.service-text {
    color: #fff;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
}

.center-proposal {
    text-align: center;
}

.tabs-design-build-works {
    position: absolute;
    right: 0;
}

.tab-design-build-work {
    margin-right: 15px;
    font-size: 25px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.design-detail-wrapped {
    margin: auto;
}

.font-size-14 {
    font-size: 11px;
}

.padding-right-off {
    padding-right: 0 !important;
}

.image-realization {
    max-height: 350px !important;
    width: -webkit-fill-available;
}

.text-realization {
    font-size: 22px;
}

.text-realization-center {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

@media screen and (max-width: 600px){
    .mt-3-sm {
        margin-top: 30px !important;
    }
}

@media screen and (min-width: 768px){
    .visibility {
        display: none;
    }
}

@media screen and (max-width: 767px){
    .visibility2 {
        display: none;
    }

    .mobile-gap {
        margin-bottom: 20px;
    }
}

.service-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px !important;
    width: 98%;
    font-weight: 900;
    color: #fff;
    border-bottom: 2px solid var(--mainSystemColor);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.mb-100 {
    margin-bottom: 60px;
}

.start_animation {
    opacity: 0;
}

.left-animation {
    animation: 2s 0s 1 come-from-left forwards;
}

.right-animation {
    animation: 2.5s 0s 1 come-from-right forwards;
}

.right-animation-slower {
    animation: 2.5s 0.3s 1 come-from-right forwards;
}

@keyframes come-from-left {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 100%;
        transform: translateX(0);
    }
}

@keyframes come-from-right {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 100%;
        transform: translateX(0);
    }
}

.card {
    background:  rgb(255, 255, 255, 0.5) !important;
    border-radius: 0 !important;
    color: white;
    border-left: 2px solid var(--mainSystemColor) !important;
}

.card-body {
    padding: 1rem 0.1rem !important;
    height: 90px;
    display: grid;
}
.card-title {
    margin-top: auto !important;
    margin-bottom: auto !important;
    font-size: 16px !important;
}

.card-image {
    width: 60px !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.card-image-navrh {
    margin-top: 16px !important;
    width: 54px !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.card-align {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.card-align-container {
    text-align: left;
}

.service-offer-background {
    background: gray;
}

.offer-image {
    width: -webkit-fill-available;
    transform: translate(0, -150px);
}

@media  screen and (max-width: 1200px){
    .offer-image {
        width: -webkit-fill-available;
        transform: translate(0, -100px);
    }

    .offer-row {
        height: 360px !important;
    }

    .long-image {
        display: none;
    }
}

@media  screen and (max-width: 1200px){
    .width-auto {
        width: auto !important;
    }
}

.offer-image-bigger {
    width: -webkit-fill-available;
}


.offer-container {
    padding: 65px;
    display: flex;
}

.offer-row {
    height: 400px;
}

.offer-text {
    text-align: center;
    padding: 10px 25px 10px 25px;
    line-height: 1.8em;
}

.offer-subtitle-margin {
    width: 95%;
    margin-left: 10px;
    text-align: left !important;
}

.margin-first-image1 {
    margin-top: 60px;
}

.margin-first-image2 {
    margin-top: 90px;
}

.margin-first-image3 {
    margin-top: 100px;
}

.margin-first-image4 {
    margin-top: 90px;
}

.margin-first-image5 {
    margin-top: 135px;
}

.margin-first-image6 {
    margin-top: 60px;
}

.margin-first-image7 {
    margin-top: 145px;
}

@media  screen and (max-width: 1200px){
    .margin-first-image1 {
        margin-top: 10px;
    }

    .margin-first-image2 {
        margin-top: 90px;
    }

    .margin-first-image3 {
        margin-top: 20px;
    }

    .margin-first-image4 {
        margin-top: 70px;
    }

    .margin-first-image5 {
        margin-top: 20px;
    }

    .margin-first-image6 {
        margin-top: 60px;
    }

    .margin-first-image7 {
        margin-top: 20px;
    }

    .offer-container {
        padding: 0 !important;
    }
}

@media  screen and (max-width: 700px){
    .offer-row {
        height: 300px;
    }

    .margin-first-image2 {
        margin-top: 80px;
    }

    .margin-first-image4 {
        margin-top: 60px;
    }

    .margin-first-image6 {
        margin-top: 50px;
    }

    .margin-first-image7 {
        margin-top: 20px;
    }

    .offer-text {
        font-size: 14px;
    }
}

@media  screen and (max-width: 500px){
    .offer-row {
        height: 300px;
    }

    .margin-first-image2 {
        margin-top: 40px;
    }

    .margin-first-image4 {
        margin-top: 40px;
    }

    .margin-first-image6 {
        margin-top: 20px;
    }

    .margin-first-image7 {
        margin-top: 20px;
    }

    .offer-text {
        font-size: 14px;
    }
}