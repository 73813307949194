.container-custom {
    width: 99%;
    display: flex;
    margin: auto;
}

.responsive-width {
    width: 49.4vw;
}

.row>* {
    padding: inherit;
}

.button-realizations {
    width: 50%;
    height: 80px;
    color: white;
    border: 0;
    font-size: 25px;
    background: var(--bs-body-color);
}

.button-realizations-border {
    border-right: 1px white solid;
}

.button-realizations:hover {
    color: black;
    background: white;
}

.image-grid {
    padding: 0 1px 0 1px !important;
}

.container-realizations {
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

@media screen and (max-width: 576px) {
    .container-realizations {
        margin-top: 15px;
    }
}

#container-realizations > div > div {
    width: 100vw !important;
    max-width: 2800px!important;
}
