.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    width: 100.1%;
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 1.05rem;
    position: sticky !important;
    top: 0;
    z-index: 999;
    padding-top: 0 !important;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
}

.navbar-logo {
    overflow: hidden;
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    margin-left: 2em;
}

.navbar-logo:hover::after {
    content:"";
    position:absolute;
    width: 114px;
    left: 35px;
    bottom: 15px;
    z-index:-1;
}

.navbar-logo-icon {
    margin-left: 10px;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(9, auto);
    grid-gap: 6px;
    list-style: none;
    text-align: center;
    width: 66%;
    justify-content: end;
    padding-right: 0;
    margin-bottom: 0 !important;
}

.nav-item-custom {
    height: 60px;
}

.nav-icons {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-icons:hover {
    transition: all 0.2s ease-out;
    color: gray;
}

.nav-links {
    color: #fff;
    display: flex;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    align-items: center;
}

.nav-links:hover {
    border-bottom: 4px solid #f4511e;
    transition: all 0.2s ;
    color: gray;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

#nav-icon.svg-inline--fa.fa-w-14, #nav-icon.svg-inline--fa.fa-w-16 {
    width: 3em;
}

.float-left {
    float: left;
}

.icon-position {
    margin-left: 10px;
    margin-top: 25px !important;
}

@media screen and (max-width: 1110px) {
    .NavbarItems {
        position: relative;
    }

    .navbar {
        height: 85px;
    }

    .nav-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 84px;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: start;
    }

    .nav-menu.active {
        display: flex;
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: .98;
    }

    .navbar-logo.active {

    }

    .nav-links {
        text-align: center;
        padding: 2em;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .icon-position {
        margin-left: 5px;
        margin-top: 25px !important;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 7px;
        right: 0;
        transform: translate(-80%, 30%);
        font-size: 1.3rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }

    .hide-preview-text {
        display: none;
    }

    .nav-item-custom {
        height: initial;
    }
}

