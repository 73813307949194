.title-about {
    font-size: 50px;
    letter-spacing: .5rem;
    font-weight: 900;
}

@media screen and (max-width: 800px){
    .title-about {
        font-size: 30px;
    }
}

.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(196, 128, 70, 0.9);
}

.about-text {
    line-height: 2.3rem;
}

.about-line:hover {
    color: rgb(200, 200, 200);
    font-size: 21px;
}

.left-card {
    font-size: 30px;
    line-height: 3.5rem;
}

.left-card-container {
    margin-top: 50px;
    padding: 40px;
}

.image {
    width: 100%;
}

.show-animation {
    animation: 3s 0s 1 show forwards;
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}