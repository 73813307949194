.contact-company {
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 900;
}

.contact-company-sro {
    font-size: 2em;
    font-weight: 900;
}

.contact-info {
    font-size: 1.2em;
}

.circle {
    border: 3px white solid;
    border-radius: 100%;
    height: 208px;
    width: 80%;
}

.circle-dropdown {
    height: 208px;
    width: 80%;
    border-radius: 100%;
}

@media screen and (max-width: 1200px) {
    .circle {
        border: 3px white solid;
        border-radius: 100%;
        height: 208px;
        width: 208px;
        margin-bottom: 20px;
    }

    .circle-dropdown {
        height: 208px;
        width: 208px;
        border-radius: 100%;
        margin-bottom: 20px;
    }
}

.circle-font {
    font-size: .65em;
}

#calculator, #price{
    background: transparent !important;
    color: white !important;
    border-bottom: 2px white solid !important;
    border-radius: 0;
    text-align: center;
    border: none;
}

#calculator:hover {
    border-bottom: 2px gray solid !important;
}

#calculator:focus, #price:focus {
    border-bottom: 2px gray solid !important;
}

#price:hover {
    border: none;
}

.calculator-width {
    width: 140px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-30 {
    margin-left: 25px;
}

#bullet {
    width: .6em !important;
}

#doc {
    background-color: rgb(33, 33, 33) !important;
    border-radius: 10px;
    color: white;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3.6rem;
}

.a-href-color {
    color: white;
    text-decoration: none;
}

.a-href-color:hover {
    color: gray;
}

.paper-plane-size {
    font-size: 1.2em;
}

.form-control {
    font-weight: 900 !important;
}

.send-button-contact {
    background:  var(--mainSystemColor) !important;
    border:  var(--mainSystemColor) !important;
}

.send-button-contact:hover {
    background: var(--mainSystemColorOpacity) !important;
    border-color: var(--mainSystemColorOpacity) !important;
}

.contact-font-size {
    font-size: 15px;
}