.vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
}

.height-150 {
    height: 150px;
}

.upload-button {
    font-size: 1.4rem;
    margin-right: 10px;
}

.width-100p {
    width: 100%;
}

.height-60 {
    height: 50px;
}

.icon-size-15 {
    font-size: 1.5em !important;
}

.header-type-of-work {
    background-color: white !important;
    color: black;
}

.border-type-of-work {
    color: white;
}

#bg-nested-dropdown {
    background: #5c636a !important;
    border: #252930;
}

.pr-20 {
    padding-right: 20px;
}
